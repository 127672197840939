import React, { useState } from 'react';
import { connect } from 'react-redux';
import { setInGame } from './redux';

const InGameMenu = props => {
  // setting values
  const [statusShow, setStatus] = useState(false);
  const [apiPush, setapiPush] = useState(false);
  let { status, menu } = props.inGame;

  // if ingame false to hiding menu
  if (!status) return false;
  if(menu.length <= 0){
    if(apiPush){
      return false;
    } 
    else
    {
      setapiPush(!apiPush)
    }
    
    fetch(process.env.REACT_APP_API_SERVER_HOST + process.env.REACT_APP_INGAME_MENU, {
      method: 'GET',
      headers: {
          'Content-Type': 'application/json'
      }
    }).then(response => response.json()).then(response => {
      if(response.status){
        props.setInGame({
          menu: response.menu
        });
        setapiPush(!apiPush)
      }

    }).catch(error => {
      setapiPush(!apiPush)
      console.log('API Error : ', error)
    })
  }

  return (
    <React.Fragment>
      <style
        dangerouslySetInnerHTML={{
          __html: `
                body {
                    background-color: #000000;
                }
                .igm {
                    display: block !important;
                    position: fixed;
                    top: 0;
                    left: 0;
                    z-index: 9999;
                    // background-color: #00000077;
                    background-color: #000000FF;
                    width: 300px;
                    height: 100%;
                    margin: 0;
                    padding: 0;
                    list-style-type: none;
                    font-size: 60px;
                    box-shadow: 10px 0 20px #000000;
                    transition: all 0.3s linear;
                    user-select: none;
                    overflow-x: hidden;
                    color: #000000;
                    overflow-y: scroll;

                }
                .igm.hide {
                    height: 75px;
                    box-shadow: 0 0 0 #000000;
                    background-color: #00000000;
                }
                .igm__slot {
                    display: block;
                    font-size: 0.4em;
                    width: 100%;
                    // padding: 10px 40px;
                    text-align: center;
                    padding: 10px 0;
                    box-sizing: border-box;
                    opacity: 1;
                    transition: all 0.3s linear;
                }
                .igm__slot.hide {
                    opacity: 0;
                    transform: translateX(-50px);
                    // height: 0;
                    // padding: 0;
                }
                .igm__btn {
                    // width: 100%;
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    box-shadow: 0 0 2px #ffffff;
                    filter: grayscale(0.8);
                    font-size: 0.7em;
                    text-align: center;
                    color: #FFFFFF;
                    // transition: all 0.3s linear;
                }
                .igm__slot:hover .igm__btn {
                    filter: grayscale(0);
                    box-shadow: 0 0 10px #ffffff;
                }
                .igm__btnShow {
                    // width: 100%;
                    // display: block;
                    // font-size: 1em;
                    // font-weight: bolder;
                    // background-color: #1ae011;
                    // text-align: center;
                    // color: #000000;
                    filter: drop-shadow( 0 0 10px #000000);
                }
                .missing {
                    opacity: 0;
                    user-select: none;
                    pointer-events: none;
                }
                ::-webkit-scrollbar {
                  width: 0;
                }
                ::-webkit-scrollbar-thumb {
                  background: transparent;
                }

            `
        }}
      />
      <ul className={'igm ' + (statusShow ? '' : 'hide')}>
        <li className="igm__slot active" key={'event_00'}>
          <div className="igm__btnShow" onClick={() => setStatus(!statusShow)}>
            <img src="https://cdngarenanow-a.akamaihd.net/webth/fo4/events/all_in_one/menu/button/all_in_one_button.png" alt="" />
          </div>
        </li>
        <li className={'igm__slot ' + (statusShow ? '' : 'hide')} />
        {menu.map((item, index) => {
          return (
            <li
              className={'igm__slot ' + (statusShow ? '' : 'hide')}
              key={'event_' + index}
              style={{ transition: 'all ' + index * 0.3 + 's linear' }}
            >
              <a className="igm__btn" href={item.url+"/ingame"+(props.landingtoken?"?landingtoken="+props.landingtoken:"")}>
                {item.img !== '' && <img src={item.img} alt="" />}
                {item.img === '' && item.title}
              </a>
            </li>
          );
        })}
      </ul>
      <div className="missing">{props.children && props.children}</div>
    </React.Fragment>
  );
};
const mapStateToProps = state => ({ inGame: state.inGame });
const mapDispatchToProps = { setInGame };
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InGameMenu);
