const defaultState = {
  cookie_name: 'fo4_in_game',
  status: false,
  menu: []
};
export default (state = defaultState, action) => {
  switch (action.type) {
    case 'SET_VALUES':
      return Object.assign({}, state, action.value);
    case '':
      return state;
    default:
      return state;
  }
};
export const setInGame = value => ({ type: 'SET_VALUES', value });

//ingame url : url/?app_id=32836&source=epc&access_token=66f1071065816fa67bfbccd71f209d1bfc983a77b52ec341fcc699db506c401c&landingtoken=
