import { Component } from "react";
import jwt_decode from "jwt-decode";
import { connect } from "react-redux";
import { getCookie, delCookie, setCookie } from "./../constants/Cookie";
import { QueryString } from "./../constants/QueryString";
import { setLoginUrl, setLogoutUrl, setValues } from "./../store/redux";

/**
 * This container deals with OAUTH authentication; thus the name "OauthMiddleware".
 */
class OauthMiddleware extends Component {
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.doLogout !== this.props.doLogout) {
      if (this.props.doLogout === true) {
        this.logout();
      }
    }
  }

  componentDidMount() {
    let loginUrl =
      process.env.REACT_APP_OAUTH_URL +
      "/oauth/login?response_type=token&client_id=" +
      process.env.REACT_APP_OAUTH_APP_ID +
      "&redirect_uri=https://auth.garena.in.th/login/callback/" +
      process.env.REACT_APP_OAUTH_APP_NAME +
      "/&locale=" +
      process.env.REACT_APP_LOCALE +
      "&all_platforms=1&platform=" +
      process.env.REACT_APP_OAUTH_PLATFORM;

    // console.log(loginUrl);
    this.props.setLoginUrl(loginUrl);

    if (QueryString[process.env.REACT_APP_OAUTH_PARAM_NAME]) {
      this.exchangeJwt(QueryString[process.env.REACT_APP_OAUTH_PARAM_NAME]);
    } else if (QueryString["access_token"]) {
      setCookie("fo4_in_game", "true", 24, "");
      setCookie("sso_session", QueryString["landingtoken"], 1, "");
      this.exchangeJwt(QueryString["access_token"]);
    }

    let jwtToken = getCookie(process.env.REACT_APP_JWT_COOKIE_NAME);
    if (jwtToken !== "") {
      let userData = null;
      try {
        userData = jwt_decode(jwtToken);
      } catch (error) {
        delCookie(
          process.env.REACT_APP_JWT_COOKIE_NAME,
          process.env.REACT_APP_COOKIE_DOMAIN
        );
        return;
      }

      this.props.setUserData({
        ...userData,
        jwtToken
      });

      let redirect =
        "https://auth.garena.in.th/login/callback/" +
        process.env.REACT_APP_OAUTH_APP_NAME +
        "/logout";
      let logoutUrl =
        process.env.REACT_APP_OAUTH_URL +
        "/oauth/logout?access_token=" +
        userData.access_token +
        "&format=redirect&redirect_uri=" +
        redirect;
      this.props.setLogoutUrl(logoutUrl);
    }
  }

  exchangeJwt(access_token) {
    let exchangeUrl =
      process.env.REACT_APP_JWT_EXCHANGE_URL +
      "?access_token=" +
      access_token +
      "&redirect=" +
      window.location.href.split("?")[0];

    window.location.href = exchangeUrl;
  }

  logout() {
    delCookie(
      process.env.REACT_APP_JWT_COOKIE_NAME,
      process.env.REACT_APP_COOKIE_DOMAIN
    );
    window.location.href = this.props.logoutUrl;
  }

  render() {
    return false;
  }
}

const mapStateToProps = (state) => ({
  loginUrl: state.Main.loginUrl,
  logoutUrl: state.Main.logoutUrl,
  userData: state.Main.userData,
  doLogout: state.Main.doLogout,
});

const mapDispatchToProps = (dispatch) => ({
  setLoginUrl: (url) => {
    dispatch(setLoginUrl(url));
  },
  setLogoutUrl: (url) => {
    dispatch(setLogoutUrl(url));
  },
  setUserData: (data) => {
    dispatch(setValues({ userData: data }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(OauthMiddleware);
